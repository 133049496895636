'use client'

import Link from 'next/link'
import Image from 'next/image'
import { useState } from 'react'
import { cn } from 'maker-ui/utils'
import { Dropdown } from 'maker-ui/popovers'

import { useAuth } from '@/hooks'
import AvatarIcon from '@/icons/avatar.svg'
import LogoutIcon from '@/icons/logout.svg'
import styles from './AccountMenu.module.scss'

export const AccountMenu = () => {
  const [show, set] = useState(false)
  const { isLoggedIn, profile, logout } = useAuth()

  const name = profile?.first_name + ' ' + profile?.last_name
  const isAdmin = profile?.user_type === 'ADMIN'

  return (
    <div className={cn('account-menu', styles.root)}>
      {isLoggedIn ? (
        <Dropdown
          classNames={{ button: styles.btn_account }}
          button="Account"
          transition="fade-up"
          closeOnBlur
          controls={[show, set]}>
          <div className={styles.container}>
            <>
              <div className={cn(styles.header, 'flex align-center')}>
                <div className={styles.image}>
                  {profile?.avatar?.url ? (
                    <Image
                      fill
                      src={profile.avatar.url}
                      alt={name}
                      style={{ objectFit: 'cover' }}
                      sizes="60px"
                    />
                  ) : (
                    <AvatarIcon className={styles.icon} />
                  )}
                </div>
                <div>
                  <div className={styles.name}>{name}</div>
                  <div className={styles.type}>{profile?.user_type}</div>
                </div>
              </div>
              <ul className={styles.menu}>
                <li>
                  <Link href="/account" onClick={() => set(false)}>
                    {isAdmin ? 'Dashboard' : 'My Account'}
                  </Link>
                </li>
                <li>
                  <a href="https://forms.gle/ukU25yT1uaMoPqT39" target="_blank">
                    Photo Upload
                  </a>
                </li>
                {isAdmin ? (
                  <li>
                    <Link
                      href="/externships/matcher"
                      onClick={() => set(false)}>
                      Match Externships
                    </Link>
                  </li>
                ) : null}
                {isAdmin ? (
                  <li>
                    <Link href="/externships" onClick={() => set(false)}>
                      Externships (Master)
                    </Link>
                  </li>
                ) : null}
                <li>
                  <button
                    className={cn(styles.btn_logout, 'flex align-center')}
                    onClick={() => {
                      logout()
                      set(false)
                    }}>
                    <LogoutIcon /> Logout
                  </button>
                </li>
              </ul>
            </>
          </div>
        </Dropdown>
      ) : (
        <Link href="/login" className={styles.btn_account}>
          Login
        </Link>
      )}
    </div>
  )
}
