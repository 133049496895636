import type { MenuItem } from 'maker-ui/layout'

/**
 * Social menu
 */
export const socialMenu = {
  facebook: 'https://facebook.com/DelbartonSchool',
  instagram: 'https://instagram.com/DelbartonSchool',
  twitter: 'https://twitter.com/delbarton',
}

/**
 * Primary header menu
 */
export const desktopMenu: MenuItem[] = [
  {
    label: 'About',
    path: '/about',
    submenu: [
      { label: 'Program Overview', path: '/about' },
      { label: 'For Students', path: '/student-information' },
      { label: 'For Hosts', path: '/host-information' },
      { label: 'Gallery', path: '/gallery' },
    ],
  },
  { label: 'Hosts', path: '/hosts' },
  { label: '2024 Externships', path: '/externships' },
  {
    label: 'Photo Upload',
    path: 'https://forms.gle/ukU25yT1uaMoPqT39',
    newTab: true,
  },
  { label: 'Contact', path: '/contact' },
]

/**
 * Mobile menu
 */

export const mobileMenu = (isLoggedIn: boolean): MenuItem[] => {
  let base: MenuItem[] = [
    { label: 'Home', path: '/' },
    { label: 'About', path: '/about' },
    { label: 'For Students', path: '/student-information' },
    { label: 'For Hosts', path: '/host-information' },
    { label: 'Gallery', path: '/gallery' },
    { label: 'Hosts', path: '/hosts' },
    { label: '2024 Externships', path: '/externships' },
    {
      label: 'Photo Upload',
      path: 'https://forms.gle/ukU25yT1uaMoPqT39',
      newTab: true,
    },
    { label: 'Contact', path: '/contact' },
  ]

  if (isLoggedIn) {
    base = [
      ...base,
      { label: 'My Account', path: '/account', className: 'auth-divider' },
    ]
  } else {
    base = [
      ...base,
      { label: 'Login', path: '/login', className: 'auth-divider' },
      { label: 'Register', path: '/register' },
    ]
  }

  return base
}

/**
 * Footer menu
 */
export const footerMenu: MenuItem[] = [
  { label: 'About', path: '/about' },
  { label: 'Gallery', path: '/gallery' },
  { label: 'For Students', path: '/student-information' },
  { label: 'For Hosts', path: '/host-information' },
  { label: 'Past Hosts', path: '/past-hosts' },
  { label: 'Contact', path: '/contact' },
]
