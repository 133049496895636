'use client'

import { SocialAccounts } from 'maker-ui/social'
import { mobileMenu, socialMenu } from '@/utils/menus'
import { cn } from 'maker-ui/utils'
import { Menu } from 'maker-ui/layout'
import { useAuth } from '@/hooks'
import { ClientOnly } from '@/components/ClientOnly'
import styles from './MobileMenu.module.scss'

export const MobileMenu = () => {
  const { isLoggedIn, logout } = useAuth()

  return (
    <div className={styles.root}>
      <Menu items={mobileMenu(isLoggedIn)} />
      <div className="mobile-menu-footer">
        {isLoggedIn && (
          <button
            className={cn(styles.btn_logout, 'btn-primary btn-logout')}
            onClick={() => logout()}>
            Logout
          </button>
        )}
        <ClientOnly>
          <SocialAccounts
            styleId="mobile-social"
            iconSize={23}
            spacing={15}
            css={{ marginTop: 20 }}
            color="var(--color-link)"
            trim
            accounts={socialMenu}
          />
        </ClientOnly>
      </div>
    </div>
  )
}
